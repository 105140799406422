// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/home/Home.vue")
        },
        {
          path: "/home-two",
          name: "Home Two",
          component: () => import("@/views/home/HomeTwo.vue")
        },
        {
          path: "/home-three",
          name: "Home Three",
          component: () => import("@/views/home/HomeThree.vue")
        },
        {
          path: "/home-four",
          name: "Home Four",
          component: () => import("@/views/home/HomeFour.vue")
        },
        {
          path: "/components",
          name: "Components",
          component: () => import("@/views/home/Index.vue")
        },
        {
          path: "/sale-page-one",
          name: "SalePageOne",
          component: () => import("@/views/pages/SalePageOne.vue")
        },
        {
          path: "/sale-page-two",
          name: "SalePageTwo",
          component: () => import("@/views/pages/SalePageTwo.vue")
        },
        {
          path: "/all-vendors",
          name: "All Vendors",
          component: () => import("@/views/vendor/AllVendors.vue")
        },
        {
          path: "/vendor-store",
          name: "Vendor Store",
          component: () => import("@/views/vendor/VendorStore.vue")
        },
        {
          path: "/search-by-spec",
          name: "Search by Spec",
          component: () => import("@/views/pages/products/SearchBySpec.vue")
        },
        {
          path: "/micro-catalogue",
          name: "Micro Catalogue",
          component: () => import("@/views/pages/products/MicroCatalogue.vue")
        },
        {
          path: "/my-favourites",
          name: "My Favourites",
          component: () => import("@/views/pages/products/MyFavourites.vue")
        },
        {
          path: "/quotes",
          name: "Quotes",
          component: () => import("@/views/pages/quotes/Quotes.vue")
        },
        {
          path: "/requisitions",
          name: "Requisitions",
          component: () => import("@/views/pages/requisitions/Requisitions.vue")
        },
        {
          path: "/my-agreements",
          name: "My Agreements",
          component: () => import("@/views/pages/agreements/MyAgreements.vue")
        },
        {
          path: "/single-product",
          name: "SingleProduct",
          component: () => import("@/views/shop/SingleProduct.vue")
        },
        {
          path: "/cart",
          name: "Cart",
          component: () => import("@/views/shop/Cart.vue")
        },
        {
          path: "/checkout-alternative",
          name: "CheckoutAlternative",
          component: () => import("@/views/shop/CheckoutAlternative.vue")
        },
        {
          path: "/vendor-order-list",
          name: "VendorOrderList",
          component: () => import("@/views/VendorOrderList.vue")
        },
        {
          path: "/order-list",
          name: "OrderList",
          component: () => import("@/views/Orders/OrderList.vue")
        },
        {
          path: "/order-details",
          name: "OrderDetails",
          component: () => import("@/views/Orders/OrderDetails.vue")
        },
        {
          path: "/list-address",
          name: "ListAddress",
          component: () => import("@/views/address/ListAddress.vue")
        },
        {
          path: "/add-address",
          name: "AddAddress",
          component: () => import("@/views/address/AddAddress.vue")
        },
        {
          path: "/view-profile",
          name: "ViewProfile",
          component: () => import("@/views/profile/ViewProfile.vue")
        },
        {
          path: "/edit-profile",
          name: "EditProfile",
          component: () => import("@/views/profile/EditProfile.vue")
        },
        {
          path: "/all-tickets",
          name: "AllTickets",
          component: () => import("@/views/AllTickets.vue")
        },
        {
          path: "/ticket-details",
          name: "TicketDetails",
          component: () => import("@/views/TicketDetails.vue")
        },
        {
          path: "/wishlist",
          name: "Wishlist",
          component: () => import("@/views/Wishlist.vue")
        },
        {
          path: "/product-list",
          name: "ProductList",
          component: () => import("@/views/ProductList.vue")
        },
        {
          path: "/edit-product",
          name: "EditProduct",
          component: () => import("@/views/EditProduct.vue")
        },
        {
          path: "/profile",
          name: "Profile",
          component: () => import("@/views/Profile.vue")
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue")
        },
        
        {
          path: "/test",
          name: "Test",
          component: () => import("@/views/test")
        },
        {
          path: "/test-2",
          name: "Test 2",
          component: () => import("@/views/test-2")
        },
      ]
    },
    {
      path: "/sign-up",
      name: "Sign Up",
      component: () => import("@/views/SignUp.vue")
    },
    {
      path: "/landing",
      name: "Landing",
      component: () => import("@/views/Landing.vue")
    },
    {
      path: "/mobile-categories",
      name: "Mobile Categories",
      component: () => import("@/views/MobileCategories.vue")
    }
  ]
});
router.beforeEach((to, from, next) => {
  
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
  next()
})
export default router;
